import React from 'react';
import styled from '@emotion/styled';

import { Icon } from '@types';

const Logo: Icon = ({ fill = 'white' }) => {
  return (
    <LogoContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={48}
        height={48}
        fill={fill}
        viewBox="-41.39 -41.39 496.71 496.71"
        className="Logo__Desktop"
      >
        <path d="M244.873 161.937c6.655 0 12.045 5.392 12.045 12.044 0 6.65-5.39 12.04-12.045 12.04-6.649 0-12.044-5.389-12.044-12.04 0-6.653 5.394-12.044 12.044-12.044z" />
        <path d="M371.029 339.641c-8.379-15.763-17.871-33.634-17.871-61.678 0-46.918 26.024-63.424 27.084-64.072 3.85-2.278 5.115-7.246 2.837-11.096a8.101 8.101 0 0 0-11.101-2.843c-1.429.85-35.021 21.39-35.021 78.011 0 32.083 10.958 52.708 19.76 69.282 6.312 11.865 11.29 21.246 11.29 31.968 0 7.683-1.672 13.23-4.83 16.062-3.217 2.879-7.42 2.452-7.499 2.452a7.894 7.894 0 0 0-1.171-.084h-29.815c3.47-5.432 5.516-11.855 5.516-18.769V13.703a8.106 8.106 0 0 0-5.933-7.805c-3.496-.986-7.225.503-9.107 3.615l-39.562 65.485c-12.139-.231-47.809-.509-154.588-.509-22.673 0-33.895.016-39.564.056L45.009 4.371C43.285 1.054 39.501-.657 35.9.234a8.102 8.102 0 0 0-6.184 7.868v370.762c0 19.28 15.681 34.969 34.966 34.969h289.382c.532.047 1.286.095 2.225.095 3.961 0 11.085-.881 17.36-6.297 7.003-6.054 10.558-15.614 10.558-28.429.001-14.75-6.396-26.805-13.178-39.561zM115.041 202.22c-15.572 0-28.242-12.667-28.242-28.239 0-15.575 12.675-28.244 28.242-28.244 15.57 0 28.242 12.669 28.242 28.244 0 15.572-12.667 28.239-28.242 28.239zm79.093 32.543h-5.982l-.654 11.253a8.1 8.1 0 0 1-8.079 7.631c-.156 0-.316-.005-.475-.011-4.464-.264-7.878-4.097-7.612-8.554l.599-10.314h-6.146a8.1 8.1 0 1 1 0-16.201h28.35c4.472 0 8.1 3.623 8.1 8.101a8.096 8.096 0 0 1-8.101 8.095zm50.739-32.543c-15.572 0-28.244-12.667-28.244-28.239 0-15.575 12.677-28.244 28.244-28.244 15.578 0 28.255 12.669 28.255 28.244-.001 15.572-12.677 28.239-28.255 28.239z" />
        <circle cx={115.041} cy={173.978} r={12.042} />
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }
`;
